import React from "react"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import EventList from "../../components/event-list"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const WorkshopHegelLesen = () => {
  return (
    <Layout backdrop="workshops">
      <Seo
        title="Hegel lesen"
        description="In einer Lesung und einem Workshop mit Veronika Reichl am 24. und 25. September werden Leseerfahrungen mit Hegel gesammelt."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Hegel lesen",
              link: "/workshops/hegel-lesen",
            },
          ]}
        />
        <PageTitle>Lesung und Workshop zum Thema "Hegel lesen"</PageTitle>
        <Paragraph dropcap={true}>
          Wie fühlt es sich an, Hegel heute zu lesen? Welche Art von Freude und
          Ärger löst es aus? Was für ein ästhetisches Erlebnis geht damit
          einher? Und: Kann man Hegel wie eine Partitur oder ein Gedicht lesen?
          In einer Lesung und einem Workshop mit Veronika Reichl am 24. und 25.
          September werden Leseerfahrungen mit Hegel gesammelt. Die Teilnahme
          ist kostenlos.
        </Paragraph>
        <EventList
          density="compact"
          showPastEvents={true}
          category="hegel-lesen"
        />
      </Stack>
    </Layout>
  )
}

export default WorkshopHegelLesen
